import {
  downloadCreditBoxReport,
  getCreditBoxReport,
  useApiMutation,
  useApiPolling,
} from '@hum/api';

export const useCreditBoxReportDownload = ({
  companyId,
  reportId,
}: {
  companyId: number;
  reportId: string;
}) => {
  const {
    data: report,
    isPolling,
    startPolling,
    stopPolling,
    isError,
  } = useApiPolling(getCreditBoxReport, {
    onPollSuccess(creditBoxReport) {
      if (creditBoxReport?.status === 'success') {
        startDownload({ companyId, creditBoxReportId: reportId });
      }
      if (creditBoxReport?.status === 'failed') {
        stopPolling();
      }
    },
  });

  const { mutate: startDownload } = useApiMutation(downloadCreditBoxReport, {
    onSuccess(data) {
      if (data.url) {
        stopPolling();
        window.location.assign(data.url);
      }
    },
  });

  return {
    report,
    isPolling,
    startDownload: () => startPolling({ companyId, reportId }),
    stopDownload: stopPolling,
    isError,
  };
};
