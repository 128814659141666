import { useEffect } from 'react';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { availableConnectorsHookUsed } from '@hum/icm-app/src/actions';
import { useFlags } from '../useFlags';
import { getAvailableConnectors, useApiQuery } from '@hum/api';
import { Result } from '@hum/common';

export const useAvailableConnectors = () => {
  const flags = useFlags();
  const { data: availableConnectors, isLoading } = useApiQuery(
    getAvailableConnectors,
    {
      enabled: flags.enabled('use-react-query'),
    }
  );
  const { state, dispatch } = useAppStore();

  useEffect(() => {
    if (!flags.enabled('use-react-query'))
      dispatch(availableConnectorsHookUsed());
  }, []);

  return flags.enabled('use-react-query')
    ? ({ data: availableConnectors, loaded: !isLoading } as Result<string[]>)
    : state.availableConnectors;
};
