// Components
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { Company, Pagination } from '@hum/types';
import { atom, useAtom } from 'jotai';
import { useFlags } from '../useFlags';
import { Result } from '@hum/common';

export const companiesAtom = atom<
  | {
      companies: Company[];
      pagination: Pagination;
    }
  | undefined
>({
  companies: [],
  pagination: {
    pageCount: 0,
    currentPage: 0,
    results: 0,
    pageSize: 0,
  },
});

export const useCompanies = () => {
  const flags = useFlags();
  const [companies] = useAtom(companiesAtom);
  const { state } = useAppStore();

  return flags.enabled('use-react-query')
    ? ({ data: companies, loaded: true } as Result<{
        companies: Company[];
        pagination: Pagination;
      }>)
    : state.companies;
};
